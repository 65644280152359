import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import HeroImage from '../../images/use-cases/heroes/isvs.png';
import PageComponent from '../../components/use-cases/PageComponent';


/*
 * Constants
 */
const features = [
    {
        key: 'overview',
        logoImg: <StaticImage src="../../images/use-cases/features/overview.png" placeholder="none" alt="" />,
        title: <Trans>Stay competitive with the latest tech.</Trans>,
        name: <Trans>Overview</Trans>,
        content: (
            <Trans>
                As a legacy company, it can be hard to keep up with increasing innovation taking place in the payments
                industry. By integrating with us you can leave that complexity behind and not worry about it ever again.
                We provide state-of-art technology so that you can offer innovative solutions to your clients. All
                rebranded to match your identity.
            </Trans>
        ),
        link: {
            url: 'https://switchpayments.com/docs/overview',
            text: <Trans>Discover Switch</Trans>,
        },
    },
    {
        key: 'processing',
        logoImg: <StaticImage src="../../images/use-cases/features/processing.png" placeholder="none" alt="" />,
        title: <Trans>Onboard all your clients effortlessly.</Trans>,
        name: <Trans>Processing</Trans>,
        content: (
            <Trans>
                Our Platform easily allows you to programmatically onboard multi-level merchant accounts through API.
                You can create accounts, users, as well as manage their permissions, channels, integrations, and
                individual setups. This can make the onboarding process faster and easier for both.
            </Trans>
        ),
        link: {
            url: '/product/processing',
            text: <Trans>Discover Processing</Trans>,
        },
    },
    {
        key: 'whitelabel',
        logoImg: <StaticImage src="../../images/use-cases/features/whitelabel.png" placeholder="none" alt="" />,
        title: <Trans>Keep the brand aesthetic with a White Label Solution.</Trans>,
        name: <Trans>Whitelabel</Trans>,
        content: (
            <Trans>
                Our products can be rebranded to match your identity. With this, you can uniformize your resources and
                customize your sub-merchants to match their own visual identity.
            </Trans>
        ),
        link: {
            url: 'https://switchpayments.com/docs/whitelabel',
            text: <Trans>Discover our Whitelabel solution</Trans>,
        },
    },
];


/*
 * Public Elements
 */
const MerchantsUseCasePage = ({data}) => {
    const {t} = useTranslation();

    const title = t('ISVs');
    const quantifier = t('dozens');
    const subtitle = t(
        'More than 50 ISVs are optimizing their payments operations with Switch. See what we can do to help you.',
    );

    const metatags = {
        title: t('White Label Payment Gateway for ISV - Switch'),
        description: t('White label payment gateway solution for ISVs, PSPs, and acquirers. Customize your accounts to '
            + 'match a custom visual identity.'),
        // eslint-disable-next-line i18next/no-literal-string
        image: '/website/metatags/use-cases-isvs.png',
    };

    return (
        <PageComponent
            data={data}
            image={HeroImage}
            title={title}
            quantifier={quantifier}
            subtitle={subtitle}
            features={features}
            metatags={metatags}
        />
    );
};

MerchantsUseCasePage.propTypes = {
    data: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default MerchantsUseCasePage;
export const query = graphql`
    query($language: String!, $useCaseType: String!) {
        ...UseCaseFragment
    }
`;
